import React from "react";

 import { Page } from "./Page";

 import { MetaWrapper } from "../../components/Meta";

 export default function DeliveryView({ deliveryOptionsList }) {
   return (
     <MetaWrapper
       meta={{
         title: "Piroshky Piroshky Bakery - Order Options",
         description: "Our delivery options",
       }}
     >
       <Page deliveryOptionsList={deliveryOptionsList} />
     </MetaWrapper>
   );
 }

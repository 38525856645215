import React from "react";
 import Link from "next/link";
 import TrackImage from "../../images/img/404piroshky.png";


 import { SubMenu } from "../../components/SubMenu";

 import { useStore } from "../../context/state";

 import Breadcrumbs from "@material-ui/core/Breadcrumbs";
 import Typography from "@material-ui/core/Typography";

 export const Page = ({ deliveryOptionsList }) => {
   const { ecommerceStoreId, apiPath } = useStore();

   return (
     <div className="background-wrapper">
       <div className="container u-xs-padding0">
         <div
           className="paper-box u-paddingLeft80 u-paddingRight80 u-xs-paddingHorizontal24"
           style={{ minHeight: "calc(100vh - 60px)" }}
         >
           <SubMenu />
           <div className="u-flexCenter u-justifyContentCenter u-marginTop30">
                <img alt="Small Food Truck Drawing" src={TrackImage} className="u-sizeFullWidth u-maxWidth300" alt="404"/>
           </div>
         </div>
       </div>
     </div>
   );
 };
